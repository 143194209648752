.brand-kit-container {
    width: 80%;
    margin: auto;
    padding: 20px;
  }
  
  .app-logo {
    width: 100px;
    height: 100px;
  }
  
  h1 {
    text-align: center;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  h2 {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  
  h2 span {
    margin-left: auto;
  }
  
  h3 {
    margin-top: 20px;
  }
  
  ul {
    padding-left: 20px;
  }
  
  .footer {
    margin-top: 50px;
    text-align: center;
  }
  