/* BusyMomSurvey.css */

.busy-mom-survey {
    font-family: Arial, sans-serif;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .app-logo {
    width: 100px;
  }
  
  .survey-container {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
  }
  
  .question {
    margin-bottom: 20px;
  }
  
  .question p {
    font-weight: bold;
  }
  
  input[type='radio'],
  input[type='checkbox'] {
    margin-right: 10px;
  }
  
  button[type='submit'] {
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  button[type='submit']:hover {
    background-color: #45a049;
  }
  
  .footer {
    margin-top: 50px;
    text-align: center;
    font-size: 12px;
    color: #666;
  }
  
  .footer a {
    color: #666;
    text-decoration: none;
  }
  
  .footer a:hover {
    text-decoration: underline;
  }
  