.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  text-align: center;
  background-color: white;
  padding: 20px;
}

.App-header {
  flex: 1;
}

.App-logo {
  width: 150px;
  height: auto;
  pointer-events: none;
}

.buttons-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.buttons-container a {
  text-decoration: none;
}

.try-now-btn,
.buy-now-btn {
  padding: 12px 20px;
  margin: 0 10px;
  width: 150px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
}

.buy-now-btn {
  background-color: #008CBA;
}

.footer {
  margin-top: auto;
  padding: 5px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 8px;
}

.LandingPage-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.LandingPage {
  width: 100%;
  max-width: 700px;
  height: auto;
  box-shadow: none;
}

.store-badges {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.store-badge {
  margin: 0 10px;
}

.store-badge img {
  width: 150px;
  height: auto;
}

/* Media query for mobile devices */
@media screen and (max-width: 600px) {
  .App {
    padding: 10px;
  }

  .App-logo {
    width: 80px;
  }

  h1 {
    font-size: 24px;
  }

  p {
    font-size: 16px;
  }

  .try-now-btn,
  .buy-now-btn {
    padding: 10px 15px;
    font-size: 16px;
    margin: 10px auto;
    display: block;
    width: 120px;
    outline: none;
    white-space: nowrap;
  }

  .footer {
    font-size: 2px;
    padding: 3px 0;
  }

  .LandingPage {
    max-width: 100%;
  }

  .store-badge img {
    width: 120px;
  }
}